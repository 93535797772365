import React from 'react';
import { cn } from '../../lib/cn';
import { TextProps } from './props';
import textVariants from './variants';

const Text = React.forwardRef<any, TextProps>((props, ref) => {
  const {
    as,
    className,
    size,
    weight,
    align,
    transform,
    color,
    ...properties
  } = props;
  const Component = as || 'span';

  return (
    <Component
      ref={ref}
      className={cn(
        textVariants({ color, size, weight, align, transform, className })
      )}
      {...properties}
    />
  );
});

export default Text;
