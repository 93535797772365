import React, { Fragment } from 'react';
import Terms from './Terms';
import { Helmet } from 'react-helmet-async';

const TermsPage = () => {
  return (
    <Fragment>
      {/* seo */}
      <Helmet>
        <title>Luuk | Terms of Service</title>
        <meta
          name='description'
          content='These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Luuk Inc, concerning your access to and use of the Services.'
        />
      </Helmet>

      <Terms />
    </Fragment>
  );
};

export default TermsPage;
