const services = [
  {
    title: 'Make Up',
    tagline:
      'Whether it is a special event, wedding, photoshoot, or just a day to treat yourself, we are dedicated to helping you feel and look your best. Our team will bring all the professional makeup and hair products necessary to create your desired look.',
  },
  {
    title: 'Creative & Artistic',
    tagline:
      'If you are seeking makeup looks that break from the ordinary and traditional, We are here to bring your vision to reality. Let your creativity flow and share your ideas with me. Together, we will craft the perfect look for your special occasion.',
  },
  {
    title: 'Bridal & Wedding',
    tagline:
      'For any bride-to-be, having makeup that stays flawless all day is crucial. Your wedding day is packed with emotions like smiles, hugs, kisses, tears, and even sweat. Luuk employs a Resistant Makeup technique that is perfect for weddings.',
  },
  {
    title: 'skin care',
    tagline:
      'Indulge in Self-Care and radiate confidence with Our Skin-Care service. From customized treatments to expert advice, We are dedicated to enhancing your natural beauty, Nourishing your skin, and Unveiling your inner glow.',
  },
  {
    title: 'academy',
    tagline:
      'Want to learn Makeup and Hair-Styling from our Academy. Discover the artistry within as we guide you through every brushstroke, Unleashing your creative potential and paving the way to success in the beauty industry.',
  },
  {
    title: 'consultation',
    tagline:
      'Unlock your beauty potential with personalized guidance and expert advice from Our consultancy service. From makeup mastery to hair-Styling secrets, We are here to help you navigate every step towards your perfect look.',
  },
];

export { services };
