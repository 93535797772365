const space = {
  default: {
    0: 'space-0',
    1: 'space-1',
    2: 'space-2',
    3: 'space-3',
    4: 'space-4',
    5: 'space-5',
    6: 'space-6',
    7: 'space-7',
    8: 'space-8',
    9: 'space-9',
    10: 'space-10',
    12: 'space-12',
    14: 'space-14',
    16: 'space-16',
    18: 'space-18',
    20: 'space-20',
    24: 'space-24',
    28: 'space-28',
  },
  xAxis: {
    0: 'space-x-0',
    1: 'space-x-1',
    2: 'space-x-2',
    3: 'space-x-3',
    4: 'space-x-4',
    5: 'space-x-5',
    6: 'space-x-6',
    7: 'space-x-7',
    8: 'space-x-8',
    9: 'space-x-9',
    10: 'space-x-10',
    12: 'space-x-12',
    14: 'space-x-14',
    16: 'space-x-16',
    18: 'space-x-18',
    20: 'space-x-20',
    24: 'space-x-24',
    28: 'space-x-28',
  },
  yAxis: {
    0: 'space-y-0',
    1: 'space-y-1',
    2: 'space-y-2',
    3: 'space-y-3',
    4: 'space-y-4',
    5: 'space-y-5',
    6: 'space-y-6',
    7: 'space-y-7',
    8: 'space-y-8',
    9: 'space-y-9',
    10: 'space-y-10',
    12: 'space-y-12',
    14: 'space-y-14',
    16: 'space-y-16',
    18: 'space-y-18',
    20: 'space-y-20',
    24: 'space-y-24',
    28: 'space-y-28',
  },
};

export default space;
