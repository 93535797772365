import React from 'react';
import Box from '../../components/Box/Box';
import Text from '../../components/Text/Text';
import Container from '../../components/Container/Container';

const Hero = () => {
  return (
    <Box mt={12}>
      <Container size={0}>
        <Box px={4}>
          <Text
            as='h1'
            size={64}
            align={'center'}
            color='pantone'
          >
            Look your best...
          </Text>
        </Box>
        <img
          src='/assets/images/two-models.jpg'
          alt='two female model'
          className='w-full h-[52rem] lg:h-[64rem] object-cover mt-12'
        />
      </Container>
    </Box>
  );
};

export default Hero;
