import { cva } from 'class-variance-authority';
import layout from '../../theme/layout';

const buttonVariants = cva(
  'px-5 text-[1.4rem] tracking-[-0.015em] flex items-center justify-center w-fit transition-all duration-300 ease-in-out',
  {
    variants: {
      variant: {
        solid: 'bg-pantone text-white hover:bg-pantone/80',
        soft: '',
        outline: '',
      },
      rounded: layout.rounded,
      size: {
        small: 'h-12',
        medium: 'h-14',
        large: 'h-20 px-6',
      },
    },

    defaultVariants: {
      variant: 'solid',
      rounded: 4,
      size: 'medium',
    },
  }
);

export default buttonVariants;
