import React, { Fragment } from 'react';
import Hero from './Hero';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
  return (
    <Fragment>
      {/* seo */}
      <Helmet>
        <title>Luuk | Contact team</title>
        <meta
          name='description'
          content='Get help and insights from our customer representative agents or inquire about pricing and any other additional information'
        />
      </Helmet>

      <Hero />
    </Fragment>
  );
};

export default ContactPage;
