import React, { forwardRef } from 'react';
import { BadgeProps } from './props';
import Flex from '../Flex/Flex';
import { cn } from '../../lib/cn';
import badgeVariants from './variants';

const Badge = forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  const {
    justifyContent = 'center',
    alignItems = 'center',
    className,
    variant,
    px = 3,
    py = 2,
    rounded = 4,
    ...properties
  } = props;

  return (
    <Flex
      ref={ref}
      px={px}
      py={py}
      rounded={rounded}
      alignItems={alignItems}
      justifyContent={justifyContent}
      className={cn(badgeVariants({ variant, className }))}
      {...properties}
    />
  );
});

export default Badge;
