import { cva } from 'class-variance-authority';

const badgeVariants = cva('', {
  variants: {
    variant: {
      solid: 'bg-pantone',
      surface: '',
      soft: '',
    },
  },

  defaultVariants: {
    variant: 'solid',
  },
});

export default badgeVariants;
