const layout = {
  display: {
    hidden: 'hidden',
    block: 'block',
    inlineBlock: 'inline-block',
    table: 'table',
  },

  height: {
    1: 'h-1',
    2: 'h-2',
    3: 'h-3',
    4: 'h-4',
    5: 'h-5',
    6: 'h-6',
    7: 'h-7',
    8: 'h-8',
    9: 'h-9',
    10: 'h-10',
    12: 'h-12',
    14: 'h-14',
    16: 'h-16',
    18: 'h-18',
    20: 'h-20',
    24: 'h-24',
    28: 'h-28',
    100: 'h-full',
  },
  minHeight: {
    1: 'min-h-1',
    2: 'min-h-2',
    3: 'min-h-3',
    4: 'min-h-4',
    5: 'min-h-5',
    6: 'min-h-6',
    7: 'min-h-7',
    8: 'min-h-8',
    9: 'min-h-9',
    10: 'min-h-10',
    12: 'min-h-12',
    14: 'min-h-14',
    16: 'min-h-16',
    18: 'min-h-18',
    20: 'min-h-20',
    24: 'min-h-24',
    28: 'min-h-28',
    100: 'min-h-100',
    screen: 'min-h-screen',
  },
  maxHeight: {
    1: 'max-h-1',
    2: 'max-h-2',
    3: 'max-h-3',
    4: 'max-h-4',
    5: 'max-h-5',
    6: 'max-h-6',
    7: 'max-h-7',
    8: 'max-h-8',
    9: 'max-h-9',
    10: 'max-h-10',
    12: 'max-h-12',
    14: 'max-h-14',
    16: 'max-h-16',
    18: 'max-h-18',
    20: 'max-h-20',
    24: 'max-h-24',
    28: 'max-h-28',
  },

  width: {
    1: 'w-1',
    2: 'w-2',
    3: 'w-3',
    4: 'w-4',
    5: 'w-5',
    6: 'w-6',
    7: 'w-7',
    8: 'w-8',
    9: 'w-9',
    10: 'w-10',
    12: 'w-12',
    14: 'w-14',
    16: 'w-16',
    18: 'w-18',
    20: 'w-20',
    24: 'w-24',
    28: 'w-28',
    100: 'w-full',
  },
  minWidth: {
    1: 'min-w-1',
    2: 'min-w-2',
    3: 'min-w-3',
    4: 'min-w-4',
    5: 'min-w-5',
    6: 'min-w-6',
    7: 'min-w-7',
    8: 'min-w-8',
    9: 'min-w-9',
    10: 'min-w-10',
    12: 'min-w-12',
    14: 'min-w-14',
    16: 'min-w-16',
    18: 'min-w-18',
    20: 'min-w-20',
    24: 'min-w-24',
    28: 'min-w-28',
  },
  maxWidth: {
    1: 'max-w-1',
    2: 'max-w-2',
    3: 'max-w-3',
    4: 'max-w-4',
    5: 'max-w-5',
    6: 'max-w-6',
    7: 'max-w-7',
    8: 'max-w-8',
    9: 'max-w-9',
    10: 'max-w-10',
    12: 'max-w-12',
    14: 'max-w-14',
    16: 'max-w-16',
    18: 'max-w-18',
    20: 'max-w-20',
    24: 'max-w-24',
    28: 'max-w-28',
  },

  overflow: {
    clip: 'overflow-clip',
    xClip: 'overflow-x-clip',
    yClip: 'overflow-y-clip',
    hidden: 'overflow-hidden',
    xHidden: 'overflow-x-hidden',
    yHidden: 'overflow-y-hidden',
    visible: 'overflow-visible',
    xVisible: 'overflow-x-visible',
    yVisible: 'overflow-y-visible',
  },

  rounded: {
    1: 'rounded-[0.25rem],',
    2: 'rounded-[0.5rem]',
    3: 'rounded-[0.75rem]',
    4: 'rounded-[1rem]',
    5: 'rounded-[1.25rem]',
    6: 'rounded-[1.5rem]',
    7: 'rounded-[1.75rem]',
    8: 'rounded-[2rem]',
    9: 'rounded-[2.25rem]',
    10: 'rounded-[2.5rem]',
    11: 'rounded-[2.75rem]',
    12: 'rounded-[3rem]',
  },
};

export default layout;
