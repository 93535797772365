import { cva } from 'class-variance-authority';
import grid from '../../theme/grid';
import gap from '../../theme/gap';
import margin from '../../theme/margin';
import padding from '../../theme/padding';
import position from '../../theme/position';
import layout from '../../theme/layout';

const gridVariants = cva('grid', {
  variants: {
    cols: grid.cols,
    rows: grid.rows,
    gap: gap.normal,
    gapX: gap.xAxis,
    gapY: gap.yAxis,
    m: margin.normal,
    ms: margin.start,
    me: margin.end,
    mt: margin.top,
    mb: margin.bottom,
    mx: margin.xAxis,
    my: margin.yAxis,
    p: padding.normal,
    ps: padding.start,
    pe: padding.end,
    pt: padding.top,
    pb: padding.bottom,
    px: padding.xAxis,
    py: padding.yAxis,
    position: position.type,
    overflow: layout.overflow,
  },
});

export default gridVariants;
