import React, { forwardRef } from 'react';
import { GridProps } from './props';
import { cn } from '../../lib/cn';
import gridVariants from './variants';

const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
  const {
    className,
    cols,
    rows,
    gap,
    gapX,
    gapY,
    m,
    ms,
    me,
    mt,
    mb,
    mx,
    my,
    p,
    ps,
    pe,
    pt,
    pb,
    px,
    py,
    position,
    overflow,
    as,
    ...properties
  } = props;
  const Component = as || 'div';

  return (
    <Component
      ref={ref}
      className={cn(
        gridVariants({
          cols,
          rows,
          gap,
          gapX,
          gapY,
          m,
          ms,
          me,
          mt,
          mb,
          mx,
          my,
          p,
          ps,
          pe,
          pt,
          pb,
          px,
          py,
          position,
          overflow,
          className,
        })
      )}
      {...properties}
    />
  );
});

export default Grid;
