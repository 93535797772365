const colors = {
  text: {
    white: 'text-white',
    pantone: 'text-pantone',
    'low-contrast': 'text-gray-11',
    'high-contrast': 'text-gray-12',
  },

  background: {
    white: 'text-white',
    pantone: 'text-pantone',
    'low-contrast': 'bg-gray-11',
    'high-contrast': 'bg-gray-12',
  },
};

export { colors };
