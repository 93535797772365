const padding = {
  normal: {
    0: 'p-0',
    1: 'p-1',
    2: 'p-2',
    3: 'p-3',
    4: 'p-4',
    5: 'p-5',
    6: 'p-6',
    7: 'p-7',
    8: 'p-8',
    9: 'p-9',
    10: 'p-10',
    12: 'p-12',
    14: 'p-14',
    16: 'p-16',
    18: 'p-18',
    20: 'p-20',
    24: 'p-24',
    28: 'p-28',
  },
  xAxis: {
    0: 'px-0',
    1: 'px-1',
    2: 'px-2',
    3: 'px-3',
    4: 'px-4',
    5: 'px-5',
    6: 'px-6',
    7: 'px-7',
    8: 'px-8',
    9: 'px-9',
    10: 'px-10',
    12: 'px-12',
    14: 'px-14',
    16: 'px-16',
    18: 'px-18',
    20: 'px-20',
    24: 'px-24',
    28: 'px-28',
  },
  yAxis: {
    0: 'py-0',
    1: 'py-1',
    2: 'py-2',
    3: 'py-3',
    4: 'py-4',
    5: 'py-5',
    6: 'py-6',
    7: 'py-7',
    8: 'py-8',
    9: 'py-9',
    10: 'py-10',
    12: 'py-12',
    14: 'py-14',
    16: 'py-16',
    18: 'py-18',
    20: 'py-20',
    24: 'py-24',
    28: 'py-28',
  },
  start: {
    0: 'pr-0',
    1: 'pr-1',
    2: 'pr-2',
    3: 'pr-3',
    4: 'pr-4',
    5: 'pr-5',
    6: 'pr-6',
    7: 'pr-7',
    8: 'pr-8',
    9: 'pr-9',
    10: 'pr-10',
    12: 'pr-12',
    14: 'pr-14',
    16: 'pr-16',
    18: 'pr-18',
    20: 'pr-20',
    24: 'pr-24',
    28: 'pr-28',
  },
  end: {
    0: 'pl-0',
    1: 'pl-1',
    2: 'pl-2',
    3: 'pl-3',
    4: 'pl-4',
    5: 'pl-5',
    6: 'pl-6',
    7: 'pl-7',
    8: 'pl-8',
    9: 'pl-9',
    10: 'pl-10',
    12: 'pl-12',
    14: 'pl-14',
    16: 'pl-16',
    18: 'pl-18',
    20: 'pl-20',
    24: 'pl-24',
    28: 'pl-28',
  },
  top: {
    0: 'pt-0',
    1: 'pt-1',
    2: 'pt-2',
    3: 'pt-3',
    4: 'pt-4',
    5: 'pt-5',
    6: 'pt-6',
    7: 'pt-7',
    8: 'pt-8',
    9: 'pt-9',
    10: 'pt-10',
    12: 'pt-12',
    14: 'pt-14',
    16: 'pt-16',
    18: 'pt-18',
    20: 'pt-20',
    24: 'pt-24',
    28: 'pt-28',
  },
  bottom: {
    0: 'pb-0',
    1: 'pb-1',
    2: 'pb-2',
    3: 'pb-3',
    4: 'pb-4',
    5: 'pb-5',
    6: 'pb-6',
    7: 'pb-7',
    8: 'pb-8',
    9: 'pb-9',
    10: 'pb-10',
    12: 'pb-12',
    14: 'pb-14',
    16: 'pb-16',
    18: 'pb-18',
    20: 'pb-20',
    24: 'pb-24',
    28: 'pb-28',
  },
};

export default padding;
