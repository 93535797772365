import React, { Fragment } from 'react';
import Hero from './Hero';
import { Helmet } from 'react-helmet-async';

const AboutPage = () => {
  return (
    <Fragment>
      {/* seo */}
      <Helmet>
        <title>Luuk | About</title>
        <meta
          name='description'
          content='We are a rapidly expanding hair and makeup artist business, fully licensed and backed by years of valuable experience'
        />
      </Helmet>

      <Hero />
    </Fragment>
  );
};

export default AboutPage;
