const service = {
  name: 'Service',
  title: 'Professional hairstylists and makeup artists straight to your door',
  tagline:
    'Book yourself a personal beauty team and get pampered on-demand in the comfort of your home, office, hotel, or any other location. ​Clients can rely on Luuk for professional luxury services across the U.S.',

  list: [
    {
      title: 'Make Up',
      tagline:
        'At Luuk, we excel in delivering exceptional service by recognizing the beauty of every client and tailoring our approach to bring your personalized vision to life. ',
      img: {
        src: 'makeup-artist-at-work.jpg',
        alt: '',
      },
    },
    {
      title: 'Hair',
      tagline:
        'We recognize that each hair type is unique, and crafting your desired luxurious look demands a tailored approach for every style by our professional hair designers.',
      img: {
        src: 'hairdo-at-salon.jpg',
        alt: '',
      },
    },
    {
      title: 'Bridal',
      tagline:
        'We provide luxurious hair and makeup treatments for you and your bridal party. Being stunningly beautiful is just as crucial as the moment itself.',
      img: {
        src: 'wedding-with-flower.jpg',
        alt: '',
      },
    },
  ],
};

export default service;
