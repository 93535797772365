import { Outlet } from 'react-router-dom';
import Header from './root/Header';
import Flex from '../components/Flex/Flex';
import Banner from './root/Banner';
import Box from '../components/Box/Box';
import Footer from './root/Footer';

const RootLayout = () => {
  return (
    <Flex
      direction={'column'}
      className='min-h-screen'
    >
      <Banner />
      <Header />
      <Box
        as='main'
        className='flex-1'
      >
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  );
};

export default RootLayout;
