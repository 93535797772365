import React from 'react';
import Box from '../../components/Box/Box';
import Container from '../../components/Container/Container';
import Text from '../../components/Text/Text';

const Terms = () => {
  return (
    <Box
      px={4}
      className='mt-24'
    >
      <Container size={2}>
        <Box spaceY={8}>
          <Text
            as='h1'
            size={48}
            color='pantone'
          >
            Terms of Service
          </Text>
          <Text
            as='h2'
            size={24}
          >
            Terms and Conditions
          </Text>
        </Box>

        <Box
          mt={16}
          spaceY={5}
        >
          <Text
            as='h3'
            size={20}
          >
            Agreement to our legal terms
          </Text>
          <Text as='p'>
            We are Luuk Inc, doing business as Luuk ("Company," "we," "us,"
            "our"), a beauty company based in United States. We operate the
            website https://luuk.com (the "Site"), as well as any other related
            products and services that refer or link to these legal terms (the
            "Legal Terms") (collectively, the "Services"). IF It is your
            responsibility to periodically review these Legal Terms to stay
            informed of updates.
          </Text>
          <Text as='p'>
            You can contact us by phone at (+1)8188578791, email at
            info@luuk.com. These Legal Terms constitute a legally binding
            agreement made between you, whether personally or on behalf of an
            entity ("you"), and Luuk Inc, concerning your access to and use of
            the Services. You agree that by accessing the Services, you have
            read, understood, and agreed to be bound by all of these Legal
            Terms.
          </Text>
          <Text as='p'>
            YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
            EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </Text>
          <Text as='p'>
            Supplemental terms and conditions or documents that may be posted on
            the Services from time to time are hereby expressly incorporated
            herein by reference. We reserve the right, in our sole discretion,
            to make changes or modifications to these Legal Terms at any time
            and for any reason. We will alert you about any changes by updating
            the "Last updated" date of these Legal Terms, and you waive any
            right to receive specific notice of each such change.
          </Text>
          <Text as='p'>
            You will be subject to, and will be deemed to have been made aware
            of and to have accepted, the changes in any revised Legal Terms by
            your continued use of the Services after the date such revised Legal
            Terms are posted. The Services are intended for users who are at
            least 18 years old. Persons under the age of 18 are not permitted to
            use or register for the Services. We recommend that you print a copy
            of these Legal Terms for your records.
          </Text>
        </Box>

        {/* our services */}
        <Box
          mt={16}
          spaceY={5}
        >
          <Text
            as='h3'
            size={20}
          >
            Our Services
          </Text>
          <Text as='p'>
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </Text>
        </Box>

        {/* intellectual property rights */}
        <Box
          mt={16}
          spaceY={5}
        >
          <Text
            as='h3'
            size={20}
          >
            Intellectual property rights
          </Text>
          <Text as='p'>
            We are the owner or the licensee of all intellectual property rights
            in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text,
            photographs, and graphics in the Services (collectively, the
            "Content"), as well as the trademarks, service marks, and logos
            contained therein (the "Marks").
          </Text>
          <Text as='p'>
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties in the United States and around the
            world. The Content and Marks are provided in or through the Services
            "AS IS" for your personal, non-commercial use only.
          </Text>
        </Box>

        {/* user representation */}
        <Box
          mt={16}
          spaceY={5}
        >
          <Text
            as='h3'
            size={20}
          >
            User representation
          </Text>
          <Text as='p'>
            By using the Services, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Legal
            Terms; (4) you are not a minor in the jurisdiction in which you
            reside; (5) you will not access the Services through automated or
            non-human means, whether through a bot, script or otherwise; (6) you
            will not use the Services for any illegal or unauthorized purpose;
            and (7) your use of the Services will not violate any applicable law
            or regulation.
          </Text>
        </Box>

        {/* purchase and payment */}
        <Box
          mt={16}
          spaceY={5}
        >
          <Text
            as='h3'
            size={20}
          >
            Purchase and Payment
          </Text>
          <Text as='p'>
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Services. You
            further agree to promptly update account and payment information,
            including email address, payment method, and payment card expiration
            date, so that we can complete your transactions and contact you as
            needed. Sales tax will be added to the price of purchases as deemed
            required by us. We may change prices at any time. All payments shall
            be in US dollars.
          </Text>
          <Text as='p'>
            You agree to pay all charges at the prices then in effect for your
            purchases and any applicable shipping fees, and you authorize us to
            charge your chosen payment provider for any such amounts upon
            placing your order. We reserve the right to correct any errors or
            mistakes in pricing, even if we have already requested or received
            payment.
          </Text>
          <Text as='p'>
            We reserve the right to refuse any order placed through the
            Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole judgment, appear to be placed by
            dealers, resellers, or distributors.
          </Text>
        </Box>

        {/* miscellaneous */}
        <Box
          mt={16}
          spaceY={5}
        >
          <Text
            as='h3'
            size={20}
          >
            Miscellaneous
          </Text>
          <Text as='p'>
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Legal Terms and the lack
            of signing by the parties hereto to execute these Legal Terms.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Terms;
