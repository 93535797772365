import { cva } from 'class-variance-authority';
import padding from '../../theme/padding';
import position from '../../theme/position';
import margin from '../../theme/margin';

const containerVariants = cva('bg-pink-5 mx-auto', {
  variants: {
    size: {
      0: 'w-full',
      1: 'sm:w-[448px]',
      2: 'md:w-[766px]',
      3: 'md:w-[990px]',
      4: 'lg:w-[1138px]',
      5: 'xl:w-[1238px]',
      6: 'xl:w-[1338px]',
      7: 'xl:w-[1438px]',
    },

    p: padding.normal,
    ps: padding.start,
    pe: padding.end,
    pt: padding.top,
    pb: padding.bottom,
    py: padding.yAxis,
    px: padding.xAxis,
    m: margin.normal,
    mt: margin.top,
    mb: margin.bottom,
    ms: margin.start,
    me: margin.end,
    mx: margin.xAxis,
    my: margin.yAxis,
    position: position.type,
    top: position.top,
  },

  defaultVariants: {
    size: 7,
  },
});

export default containerVariants;
