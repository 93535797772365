const typography = {
  size: {
    12: 'text-[1.2rem] leading-[1.5]',
    13: 'text-[1.3rem] leading-[1.4]',
    14: 'text-[1.4rem] leading-[1.4]',
    16: 'text-[1.6rem] leading-[1.4]',
    18: 'text-[1.8rem] leading-[1.3]',
    20: 'text-[2.0rem] leading-[1.3]',
    24: 'text-[2.0rem] md:text-[2.4rem] leading-[1.3]',
    28: 'text-[2.4rem] md:text-[2.8rem] leading-[1.3]',
    32: 'text-[3.2rem] leading-[1.2]',
    36: 'text-[3.2rem] lg:text-[3.6rem] leading-[1.2]',
    40: 'text-[3.2rem] lg:text-[4.0rem] leading-[1.2]',
    48: 'text-[4.0rem] lg:text-[4.8rem] leading-[1.2]',
    56: 'text-[4.0rem] lg:text-[5.6rem] leading-[1.2]',
    64: 'text-[4.8rem] md:text-[5.6rem] lg:text-[6.4rem] leading-[1.2]',
    80: 'text-[4.8rem] md:text-[6.4rem] lg:text-[8.0rem] leading-[1.15]',
    96: 'text-[6.4rem] md:text-[8.4rem] lg:text-[9.6rem] leading-[1.1]',
  },

  align: {
    left: 'text-left',
    right: 'text-right',
    center: 'text-center',
  },

  weight: {
    100: 'font-[100]',
    200: 'font-[200]',
    300: 'font-[300]',
    400: 'font-[400]',
    500: 'font-[500]',
    600: 'font-[600]',
    700: 'font-[700]',
  },

  transform: {
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    capitalize: 'capitalize',
  },
};

export { typography };
