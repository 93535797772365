const gap = {
  normal: {
    0: 'gap-0',
    1: 'gap-1',
    2: 'gap-2',
    3: 'gap-3',
    4: 'gap-4',
    5: 'gap-5',
    6: 'gap-6',
    7: 'gap-7',
    8: 'gap-8',
    9: 'gap-9',
    10: 'gap-10',
    12: 'gap-12',
    14: 'gap-14',
    16: 'gap-16',
    18: 'gap-18',
    20: 'gap-20',
    24: 'gap-24',
    28: 'gap-28',
  },
  xAxis: {
    0: 'gap-x-0',
    1: 'gap-x-1',
    2: 'gap-x-2',
    3: 'gap-x-3',
    4: 'gap-x-4',
    5: 'gap-x-5',
    6: 'gap-x-6',
    7: 'gap-x-7',
    8: 'gap-x-8',
    9: 'gap-x-9',
    10: 'gap-x-10',
    12: 'gap-x-12',
    14: 'gap-x-14',
    16: 'gap-x-16',
    18: 'gap-x-18',
    20: 'gap-x-20',
    24: 'gap-x-24',
    28: 'gap-x-28',
  },
  yAxis: {
    0: 'gap-y-0',
    1: 'gap-y-1',
    2: 'gap-y-2',
    3: 'gap-y-3',
    4: 'gap-y-4',
    5: 'gap-y-5',
    6: 'gap-y-6',
    7: 'gap-y-7',
    8: 'gap-y-8',
    9: 'gap-y-9',
    10: 'gap-y-10',
    12: 'gap-y-12',
    14: 'gap-y-14',
    16: 'gap-y-16',
    18: 'gap-y-18',
    20: 'gap-y-20',
    24: 'gap-y-24',
    28: 'gap-y-28',
  },
};

export default gap;
