import React from 'react';
import Box from '../../components/Box/Box';
import Text from '../../components/Text/Text';
import Container from '../../components/Container/Container';

const Banner = () => {
  return (
    <Box
      p={2}
      className='bg-gray-200'
    >
      <Container
        p={1}
        size={0}
      >
        <Text
          as='p'
          size={14}
          weight={400}
          align={'center'}
          color='pantone'
        >
          Get your hairdo and makeup from professionals for 25% less.
        </Text>
      </Container>
    </Box>
  );
};

export default Banner;
