import React, { Fragment } from 'react';
import Hero from './Hero';
import Service from './Service';
import Section from './Section';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Luuk | Look sooo fantastic...</title>
        <meta
          name='description'
          content='Book yourself a personal beauty team and get pampered on-demand in the comfort of your home, office, hotel, or any other location. ​ Clients can rely on Luuk for professional luxury services across the U.S.'
        />
      </Helmet>

      <Hero />
      <Service />
      <Section />
    </Fragment>
  );
};

export default HomePage;
