const reasons = [
  {
    title: 'Our Goal',
    tagline:
      'Our goal is simple: to provide our clients with nothing less than the absolute best on their special day by using top-quality products, she ensures that her clients achieve a stunning radiant look that lasts.',
  },
  {
    title: 'We are faster',
    tagline:
      'Getting production beauty services has never been easier with our 24-hour telephone support and access to agents through our international toll-free number. No matter the size of your project or production, we are here to cater to your needs.',
  },
  {
    title: 'Convenience',
    tagline:
      'You can easily book any of our services online at your convenience, securely through our website, 24/7. We believe in transparent pricing, so you will always know the rates upfront with no hidden costs.',
  },
  {
    title: 'Versatility',
    tagline:
      'We are experts in providing beauty and production services for all occasions! Whether it is weddings, a night out, corporate events, or any other special occasion, we have got you covered. No matter the size of the job, we are here to make it perfect for you.',
  },
];

export default reasons;
