import { cva } from 'class-variance-authority';
import { typography } from '../../theme/typography';
import { colors } from '../../theme/colors';

const textVariants = cva(
  'text-pretty tracking-[-0.03em] transition-all duration-300 ease-in-out',
  {
    variants: {
      size: typography.size,
      weight: typography.weight,
      align: typography.align,
      transform: typography.transform,
      color: colors.text,
    },

    defaultVariants: {
      size: 16,
      weight: 300,
      color: 'high-contrast',
    },
  }
);

export default textVariants;
