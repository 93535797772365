import React, { forwardRef } from 'react';
import { BoxProps } from './props';
import { cn } from '../../lib/cn';
import boxVariants from './variants';

const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const {
    as,
    className,
    p,
    pt,
    pb,
    py,
    ps,
    pe,
    px,
    m,
    ms,
    me,
    mb,
    mt,
    mx,
    my,
    height,
    width,
    display,
    position,
    space,
    spaceX,
    spaceY,
    ...properties
  } = props;

  const Component = as || 'div';

  return (
    <Component
      ref={ref}
      className={cn(
        boxVariants({
          display,
          position,
          height,
          width,
          p,
          ps,
          pe,
          px,
          pt,
          pb,
          py,
          m,
          mt,
          mb,
          mx,
          my,
          ms,
          me,
          space,
          spaceX,
          spaceY,
          className,
        })
      )}
      {...properties}
    />
  );
});

export default Box;
