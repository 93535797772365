import React from 'react';
import Box from '../../components/Box/Box';
import Container from '../../components/Container/Container';
import Text from '../../components/Text/Text';
import Grid from '../../components/Grid/Grid';
import { Link } from 'react-router-dom';

const services = [
  'Make Up',
  'Creative & Artistic',
  'Bridal & Wedding',
  'Skin Care',
  'Academy',
  'Consultation',
];

const legals = ['Terms & Conditions', 'Privacy Policy', 'Purchase & Payment'];
const contacts = ['+1 234 567 8900', 'info@luuk.com'];

const Footer = () => {
  return (
    <Box
      as='footer'
      pb={8}
      px={4}
      className='pt-32 mt-64 bg-gray-950'
    >
      <Container>
        <Grid
          cols={1}
          gap={12}
          className='sm:grid-cols-3 lg:grid-cols-6'
        >
          <Box
            spaceY={4}
            className='col-span-full lg:col-span-3 lg:w-2/3'
          >
            <Text
              as='h2'
              size={32}
              color='white'
            >
              Hire us Today
            </Text>
            <Text
              as='p'
              color='white'
            >
              Our professional team is punctual and prepared to ensure your
              beauty is flawless. With expertise in the industry, we exclusively
              use top-of-the-line makeup and hair products.
            </Text>
            <Link to={'mail:info@business.com'}>
              <Text>Info@business.com</Text>
            </Link>
          </Box>

          <Box spaceY={5}>
            <Text
              as='h2'
              color='white'
            >
              Services
            </Text>

            <Box spaceY={4}>
              {services.map((service) => (
                <Link
                  key={service}
                  to={'/services/'}
                  className='block'
                >
                  <Text
                    as='p'
                    size={13}
                    className='text-gray-300 hover:text-white'
                  >
                    {service}
                  </Text>
                </Link>
              ))}
            </Box>
          </Box>

          <Box spaceY={5}>
            <Text
              as='h2'
              color='white'
            >
              Legal
            </Text>

            <Box spaceY={4}>
              {legals.map((legal) => (
                <Link
                  key={legal}
                  to={'/terms/'}
                  className='block'
                >
                  <Text
                    as='p'
                    size={13}
                    className='text-gray-300 hover:text-white'
                  >
                    {legal}
                  </Text>
                </Link>
              ))}
            </Box>
          </Box>

          <Box spaceY={5}>
            <Text
              as='h2'
              color='white'
            >
              Contact
            </Text>

            <Box spaceY={3}>
              {contacts.map((contact) => (
                <Link
                  key={contact}
                  to={'/terms/'}
                  className='block'
                >
                  <Text
                    as='p'
                    size={13}
                    className='text-gray-300 hover:text-white'
                  >
                    {contact}
                  </Text>
                </Link>
              ))}
            </Box>
          </Box>

          <Box
            className='col-span-full'
            mt={20}
          >
            <Text
              size={14}
              color='white'
            >
              &copy; Luuk Inc. All rights reserved.
            </Text>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
