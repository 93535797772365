import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/home/Page';
import RootLayout from './layouts/RootLayout';
import './assets/styles/global.css';
import ServicesPage from './pages/services/Page';
import ContactPage from './pages/contact/Page';
import AboutPage from './pages/about/Page';
import TermsPage from './pages/terms/Page';
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'services', element: <ServicesPage /> },
      { path: 'contact', element: <ContactPage /> },
      { path: 'about', element: <AboutPage /> },
      { path: 'terms', element: <TermsPage /> },
    ],
  },
]);

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />;
    </HelmetProvider>
  );
}

export default App;
