import React, { forwardRef } from 'react';
import { FlexProps } from './props';
import { cn } from '../../lib/cn';
import flexVariants from './variants';

const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const {
    as,
    className,
    direction,
    alignItems,
    justifyContent,
    p,
    ps,
    pe,
    pt,
    pb,
    px,
    py,
    m,
    mt,
    mb,
    ms,
    me,
    mx,
    my,
    gap,
    gapX,
    gapY,
    width,
    rounded,
    position,
    top,
    ...properties
  } = props;
  const Component = as || 'div';

  return (
    <Component
      ref={ref}
      className={cn(
        flexVariants({
          direction,
          alignItems,
          justifyContent,
          p,
          ps,
          pe,
          pt,
          pb,
          px,
          py,
          m,
          mb,
          mt,
          ms,
          me,
          mx,
          my,
          gap,
          gapX,
          gapY,
          width,
          rounded,
          position,
          top,
          className,
        })
      )}
      {...properties}
    />
  );
});

export default Flex;
