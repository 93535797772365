const position = {
  type: {
    fixed: 'fixed',
    sticky: 'sticky',
    relative: 'relative',
    absolute: 'absolute',
  },

  top: {
    0: 'top-0',
    1: 'top-1',
    2: 'top-2',
    3: 'top-3',
    4: 'top-4',
    5: 'top-5',
    6: 'top-6',
    7: 'top-7',
    8: 'top-8',
    9: 'top-9',
    10: 'top-10',
    12: 'top-12',
    14: 'top-14',
    16: 'top-16',
    20: 'top-20',
    24: 'top-24',
    28: 'top-28',
    32: 'top-32',
    36: 'top-36',
    40: 'top-40',
  },

  bottom: {
    0: 'bottom-0',
    1: 'bottom-1',
    2: 'bottom-2',
    3: 'bottom-3',
    4: 'bottom-4',
    5: 'bottom-5',
    6: 'bottom-6',
    7: 'bottom-7',
    8: 'bottom-8',
    9: 'bottom-9',
    10: 'bottom-10',
    12: 'bottom-12',
    14: 'bottom-14',
    16: 'bottom-16',
    20: 'bottom-20',
    24: 'bottom-24',
    28: 'bottom-28',
    32: 'bottom-32',
    36: 'bottom-36',
    40: 'bottom-40',
  },

  left: {
    0: 'left-0',
    1: 'left-1',
    2: 'left-2',
    3: 'left-3',
    4: 'left-4',
    5: 'left-5',
    6: 'left-6',
    7: 'left-7',
    8: 'left-8',
    9: 'left-9',
    10: 'left-10',
    12: 'left-12',
    14: 'left-14',
    16: 'left-16',
    20: 'left-20',
    24: 'left-24',
    28: 'left-28',
    32: 'left-32',
    36: 'left-36',
    40: 'left-40',
  },
  right: {
    0: 'right-0',
    1: 'right-1',
    2: 'right-2',
    3: 'right-3',
    4: 'right-4',
    5: 'right-5',
    6: 'right-6',
    7: 'right-7',
    8: 'right-8',
    9: 'right-9',
    10: 'right-10',
    12: 'right-12',
    14: 'right-14',
    16: 'right-16',
    20: 'right-20',
    24: 'right-24',
    28: 'right-28',
    32: 'right-32',
    36: 'right-36',
    40: 'right-40',
  },

  inset: {
    0: 'inset-0',
    1: 'inset-1',
    2: 'inset-2',
    3: 'inset-3',
    4: 'inset-4',
    5: 'inset-5',
    6: 'inset-6',
    7: 'inset-7',
    8: 'inset-8',
    9: 'inset-9',
    10: 'inset-10',
    12: 'inset-12',
    14: 'inset-14',
    16: 'inset-16',
    20: 'inset-20',
    24: 'inset-24',
    28: 'inset-28',
    32: 'inset-32',
    36: 'inset-36',
    40: 'inset-40',
  },

  insetXAxis: {
    0: 'inset-x-0',
    1: 'inset-x-1',
    2: 'inset-x-2',
    3: 'inset-x-3',
    4: 'inset-x-4',
    5: 'inset-x-5',
    6: 'inset-x-6',
    7: 'inset-x-7',
    8: 'inset-x-8',
    9: 'inset-x-9',
    10: 'inset-x-10',
    12: 'inset-x-12',
    14: 'inset-x-14',
    16: 'inset-x-16',
    20: 'inset-x-20',
    24: 'inset-x-24',
    28: 'inset-x-28',
    32: 'inset-x-32',
    36: 'inset-x-36',
    40: 'inset-x-40',
  },
  insetYAxis: {
    0: 'inset-y-0',
    1: 'inset-y-1',
    2: 'inset-y-2',
    3: 'inset-y-3',
    4: 'inset-y-4',
    5: 'inset-y-5',
    6: 'inset-y-6',
    7: 'inset-y-7',
    8: 'inset-y-8',
    9: 'inset-y-9',
    10: 'inset-y-10',
    12: 'inset-y-12',
    14: 'inset-y-14',
    16: 'inset-y-16',
    20: 'inset-y-20',
    24: 'inset-y-24',
    28: 'inset-y-28',
    32: 'inset-y-32',
    36: 'inset-y-36',
    40: 'inset-y-40',
  },
};

export default position;
