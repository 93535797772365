import React from 'react';
import Box from '../../components/Box/Box';
import { NavLink } from 'react-router-dom';
import Text from '../../components/Text/Text';
import Button from '../../components/Button/Button';

const urls = [
  { name: 'services', path: 'services' },
  { name: 'about', path: 'about' },
  { name: 'contact', path: 'contact' },
  { name: 'policy', path: 'terms' },
];

type Props = {
  onClose: () => void;
};

const MobileMenu = ({ onClose }: Props) => {
  return (
    <Box
      px={4}
      width={100}
      position={'fixed'}
      className='top-0 left-0 z-10 h-screen bg-white/95'
    >
      <Box mt={8}>
        <Button
          onClick={onClose}
          className='rounded-full'
        >
          X
        </Button>
      </Box>

      <Box
        mt={12}
        spaceY={8}
      >
        {urls.map((url) => (
          <NavLink
            to={url.path}
            onClick={onClose}
            className={'block'}
          >
            {({ isActive }) => (
              <Text
                as='p'
                size={48}
                transform={'capitalize'}
                color={isActive ? 'high-contrast' : 'low-contrast'}
              >
                {url.name}
              </Text>
            )}
          </NavLink>
        ))}
      </Box>
    </Box>
  );
};

export default MobileMenu;
