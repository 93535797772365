const margin = {
  normal: {
    0: 'm-0',
    1: 'm-1',
    2: 'm-2',
    3: 'm-3',
    4: 'm-4',
    5: 'm-5',
    6: 'm-6',
    7: 'm-7',
    8: 'm-8',
    9: 'm-9',
    10: 'm-10',
    12: 'm-12',
    14: 'm-14',
    16: 'm-16',
    18: 'm-18',
    20: 'm-20',
    24: 'm-24',
    28: 'm-28',
  },
  xAxis: {
    0: 'mx-0',
    1: 'mx-1',
    2: 'mx-2',
    3: 'mx-3',
    4: 'mx-4',
    5: 'mx-5',
    6: 'mx-6',
    7: 'mx-7',
    8: 'mx-8',
    9: 'mx-9',
    10: 'mx-10',
    12: 'mx-12',
    14: 'mx-14',
    16: 'mx-16',
    18: 'mx-18',
    20: 'mx-20',
    24: 'mx-24',
    28: 'mx-28',
  },
  yAxis: {
    0: 'my-0',
    1: 'my-1',
    2: 'my-2',
    3: 'my-3',
    4: 'my-4',
    5: 'my-5',
    6: 'my-6',
    7: 'my-7',
    8: 'my-8',
    9: 'my-9',
    10: 'my-10',
    12: 'my-12',
    14: 'my-14',
    16: 'my-16',
    18: 'my-18',
    20: 'my-20',
    24: 'my-24',
    28: 'my-28',
  },
  start: {
    0: 'mr-0',
    1: 'mr-1',
    2: 'mr-2',
    3: 'mr-3',
    4: 'mr-4',
    5: 'mr-5',
    6: 'mr-6',
    7: 'mr-7',
    8: 'mr-8',
    9: 'mr-9',
    10: 'mr-10',
    12: 'mr-12',
    14: 'mr-14',
    16: 'mr-16',
    18: 'mr-18',
    20: 'mr-20',
    24: 'mr-24',
    28: 'mr-28',
  },
  end: {
    0: 'ml-0',
    1: 'ml-1',
    2: 'ml-2',
    3: 'ml-3',
    4: 'ml-4',
    5: 'ml-5',
    6: 'ml-6',
    7: 'ml-7',
    8: 'ml-8',
    9: 'ml-9',
    10: 'ml-10',
    12: 'ml-12',
    14: 'ml-14',
    16: 'ml-16',
    18: 'ml-18',
    20: 'ml-20',
    24: 'ml-24',
    28: 'ml-28',
  },
  top: {
    0: 'mt-0',
    1: 'mt-1',
    2: 'mt-2',
    3: 'mt-3',
    4: 'mt-4',
    5: 'mt-5',
    6: 'mt-6',
    7: 'mt-7',
    8: 'mt-8',
    9: 'mt-9',
    10: 'mt-10',
    12: 'mt-12',
    14: 'mt-14',
    16: 'mt-16',
    18: 'mt-18',
    20: 'mt-20',
    24: 'mt-24',
    28: 'mt-28',
  },
  bottom: {
    0: 'mb-0',
    1: 'mb-1',
    2: 'mb-2',
    3: 'mb-3',
    4: 'mb-4',
    5: 'mb-5',
    6: 'mb-6',
    7: 'mb-7',
    8: 'mb-8',
    9: 'mb-9',
    10: 'mb-10',
    12: 'mb-12',
    14: 'mb-14',
    16: 'mb-16',
    18: 'mb-18',
    20: 'mb-20',
    24: 'mb-24',
    28: 'mb-28',
  },
};

export default margin;
