import React from 'react';
import Box from '../../components/Box/Box';
import Container from '../../components/Container/Container';
import Grid from '../../components/Grid/Grid';
import Text from '../../components/Text/Text';
import Flex from '../../components/Flex/Flex';
import { services } from './data';

const List = () => {
  return (
    <Box className='mt-80'>
      <Container>
        <Grid
          cols={1}
          gap={24}
          className='sm:grid-cols-2 lg:grid-cols-3'
        >
          {services.map((services, index) => (
            <Flex
              px={8}
              key={services.title}
              direction={'column'}
              justifyContent={'between'}
              className='border-l-[4px] border-l-pantone min-h-[56rem]'
            >
              <Box spaceY={5}>
                <Text
                  as='h2'
                  size={28}
                  transform={'capitalize'}
                  color='pantone'
                >
                  {services.title}
                </Text>
                <Text
                  as='p'
                  size={18}
                >
                  {services.tagline}
                </Text>
              </Box>

              <Text size={96}>0{index + 1}</Text>
            </Flex>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default List;
