import React, { useState } from 'react';
import Box from '../../components/Box/Box';
import Container from '../../components/Container/Container';
import Text from '../../components/Text/Text';
import Flex from '../../components/Flex/Flex';
import { Link, NavLink } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { twMerge } from 'tailwind-merge';
import MobileMenu from './MobileMenu';

const urls = [
  { name: 'services', path: 'services' },
  { name: 'about', path: 'about' },
  { name: 'contact', path: 'contact' },
  { name: 'policy', path: 'terms' },
];

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Box
      as='header'
      px={4}
      className='sticky top-0 z-10 bg-white border-b backdrop-blur-md'
    >
      <Container py={4}>
        <Flex
          justifyContent={'between'}
          alignItems={'center'}
        >
          {/* replace the text with a logo */}
          <Link to={'/'}>
            <img
              src='/assets/images/svg/light-inverted.svg'
              alt='luuk brand identity'
              className='w-36'
            />
          </Link>

          {/* navigation menu */}
          <Box>
            {showMobileMenu ? (
              <MobileMenu onClose={() => setShowMobileMenu(false)} />
            ) : null}
            <Flex
              as='nav'
              gapX={3}
              className='hidden md:flex'
            >
              {urls.map((url) => (
                <NavLink
                  to={url.path}
                  key={url.name}
                >
                  {({ isActive, isPending, isTransitioning }) => (
                    <Text
                      size={16}
                      weight={400}
                      color='low-contrast'
                      transform={'capitalize'}
                      className={twMerge(
                        'hover:bg-gray-200 py-2 px-4 rounded-[1rem]',
                        isActive && 'bg-gray-600 text-white hover:bg-gray-500'
                      )}
                    >
                      {isPending || isTransitioning ? 'pending' : url.name}
                    </Text>
                  )}
                </NavLink>
              ))}
            </Flex>
          </Box>

          <Flex gapX={3}>
            <Button
              onClick={() => setShowMobileMenu(true)}
              className='md:hidden'
            >
              &#9777;
            </Button>
            <Button className='hidden md:flex'>Hire Us</Button>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
