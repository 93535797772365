import React from 'react';
import Box from '../../components/Box/Box';
import Flex from '../../components/Flex/Flex';
import Text from '../../components/Text/Text';
import Container from '../../components/Container/Container';

const Hero = () => {
  return (
    <Box
      position={'relative'}
      style={{
        backgroundImage: 'url(../assets/images/two-models-with-makeup.jpg)',
      }}
      className='flex-1 bg-no-repeat bg-cover min-h-[72rem]'
    >
      <Flex
        top={0}
        width={100}
        direction={'column'}
        position='absolute'
        justifyContent={'center'}
        className='h-full bg-black/30'
      >
        <Container size={2}>
          <Text
            as='p'
            size={96}
            align={'right'}
            color='white'
            className='text-center'
          >
            Look so fantastic...
          </Text>
        </Container>
      </Flex>
    </Box>
  );
};

export default Hero;
