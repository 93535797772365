import React, { forwardRef } from 'react';
import { ContainerProps } from './props';
import { cn } from '../../lib/cn';
import containerVariants from './variants';

const Container = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
  const { className, as, size, p, py, position, ...properties } = props;
  const Component = as || 'div';

  return (
    <Component
      ref={ref}
      className={cn(containerVariants({ size, p, py, position, className }))}
      {...properties}
    />
  );
});

export default Container;
