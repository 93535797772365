import React, { forwardRef } from 'react';
import { ButtonProps } from './props';
import { cn } from '../../lib/cn';
import buttonVariants from './variants';

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, variant, size, disabled = false, ...properties } = props;

  return (
    <button
      ref={ref}
      disabled={disabled}
      className={cn(buttonVariants({ variant, size, className }))}
      {...properties}
    />
  );
});

export default Button;
