import React from 'react';
import Box from '../../components/Box/Box';
import Container from '../../components/Container/Container';
import Text from '../../components/Text/Text';
import Flex from '../../components/Flex/Flex';
import Badge from '../../components/Badge/Badge';

const Hero = () => {
  return (
    <Box
      px={4}
      mt={20}
    >
      <Container size={1}>
        <Box spaceY={5}>
          <Text
            as='h1'
            size={64}
            align={'center'}
          >
            We are here to assist you
          </Text>
          <Text
            as='p'
            size={18}
            align={'center'}
          >
            Get help and insights from our customer representative agents or
            inquire about pricing and any other additional information
          </Text>
          <Flex
            gapX={3}
            justifyContent={'center'}
          >
            <Badge>
              <Text
                size={13}
                color='white'
                weight={500}
              >
                Mobile: +1 234 268 2672
              </Text>
            </Badge>
            <Badge>
              <Text
                size={13}
                color='white'
                weight={500}
              >
                Email: info@luuk.com
              </Text>
            </Badge>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
