import React from 'react';
import Box from '../../components/Box/Box';
import Grid from '../../components/Grid/Grid';
import Container from '../../components/Container/Container';
import Text from '../../components/Text/Text';

const Section = () => {
  return (
    <Box
      px={4}
      className='mt-80'
    >
      <Container>
        <Grid
          gap={20}
          cols={1}
          className='lg:grid-cols-3'
        >
          <Box spaceY={10}>
            <Box spaceY={4}>
              <Text
                as='h2'
                size={48}
                color='pantone'
              >
                Look Bold. Sharp. Beautiful.
              </Text>
              <Text
                as='p'
                size={20}
              >
                Are you in search of a skilled and reliable bridal makeup artist
                and hairstylist in the United States? Luuk provides convenient
                mobile services for weddings, special occasions, and destination
                weddings.
              </Text>
            </Box>
            <Box spaceY={4}>
              <Text
                as='h3'
                size={24}
              >
                Broad beauty services
              </Text>
              <Text as='p'>
                Our menu of services boasts a diverse range, delivered by
                skilled professionals with top-notch training. We utilize
                cutting-edge products from the beauty industry to ensure
                excellence in every session.
              </Text>
            </Box>
          </Box>
          {/* image */}
          <img
            src='/assets/images/rotated-model.jpg'
            alt='make-brush'
            className='object-cover rounded-[2.5rem] h-[30rem] lg:h-[60rem] w-full'
          />
          {/* sub sections */}
          <Box spaceY={8}>
            <Box spaceY={4}>
              <Text
                as='h3'
                size={24}
              >
                What?
              </Text>
              <Text as='p'>
                Indulge in a range of luxury services, from makeup and
                hairstyling to manicures and spray tans, all provided by your
                own personal mobile Luuk squad.
              </Text>
            </Box>
            <Box spaceY={4}>
              <Text
                as='h3'
                size={24}
              >
                When?
              </Text>
              <Text as='p'>
                We're available to assist you anytime, day or night, every day
                of the year. Your beauty needs are always met on your schedule.
              </Text>
            </Box>
            <Box spaceY={4}>
              <Text
                as='h3'
                size={24}
              >
                Where?
              </Text>
              <Text as='p'>
                Whether you're at home, the gym, the office, the beach, or
                backstage, we're here to accommodate you wherever you are. Our
                mobile service brings beauty right to your doorstep.
              </Text>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Section;
