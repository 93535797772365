import React from 'react';
import Box from '../../components/Box/Box';
import Grid from '../../components/Grid/Grid';
import Text from '../../components/Text/Text';
import Container from '../../components/Container/Container';
import service from './data';

const Service = () => {
  return (
    <Box
      px={4}
      className='mt-80'
    >
      <Container>
        <Grid
          cols={1}
          gap={24}
          className='lg:grid-cols-12'
        >
          {/* first box */}
          <Box className='lg:col-span-4'>
            <Text>{service.name}</Text>
          </Box>
          {/* second box */}
          <Box
            spaceY={8}
            className='lg:col-span-5'
          >
            <Text
              as='h2'
              size={40}
              color='pantone'
            >
              {service.title}
            </Text>
            <Text
              as='p'
              size={24}
            >
              {service.tagline}
            </Text>
          </Box>
          {/* third box */}
          {service.list.map((item) => (
            <Box
              spaceY={4}
              className='lg:col-span-4 lg:w-3/4'
            >
              <Text
                as='h4'
                size={24}
              >
                {item.title}
              </Text>
              <Text as='p'>{item.tagline}</Text>
              {/* image */}
              <img
                alt={item.img.alt}
                src={`/assets/images/${item.img.src}`}
                className='w-full h-[25rem] rounded-[2.5rem] object-cover'
              />
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Service;
