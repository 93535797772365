import { cva } from 'class-variance-authority';
import flex from '../../theme/flex';
import margin from '../../theme/margin';
import padding from '../../theme/padding';
import gap from '../../theme/gap';
import layout from '../../theme/layout';
import position from '../../theme/position';

const flexVariants = cva('flex', {
  variants: {
    direction: flex.direction,
    alignItems: flex.alignItems,
    justifyContent: flex.justifyContent,
    m: margin.normal,
    ms: margin.start,
    me: margin.end,
    mb: margin.bottom,
    mt: margin.top,
    mx: margin.xAxis,
    my: margin.yAxis,
    p: padding.normal,
    ps: padding.start,
    pe: padding.end,
    pt: padding.top,
    pb: padding.bottom,
    px: padding.xAxis,
    py: padding.yAxis,
    gap: gap.normal,
    gapX: gap.xAxis,
    gapY: gap.yAxis,
    width: layout.width,
    position: position.type,
    top: position.top,
    rounded: layout.rounded,
  },
});

export default flexVariants;
