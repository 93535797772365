import React from 'react';
import Box from '../../components/Box/Box';
import Container from '../../components/Container/Container';
import Grid from '../../components/Grid/Grid';
import Text from '../../components/Text/Text';
import reasons from './data';

const Hero = () => {
  return (
    <Box
      px={4}
      className='mt-24'
    >
      <Container>
        <Grid
          cols={1}
          gap={24}
          className='lg:grid-cols-4'
        >
          <Box spaceY={4}>
            <Text
              as='h3'
              size={24}
            >
              Who are we?
            </Text>
            <Text
              as='p'
              size={18}
            >
              We're a rapidly expanding hair and makeup artist business, fully
              licensed and backed by years of valuable experience. Our expertise
              goes beyond just mastering our craft; we're passionate about
              sharing our knowledge and skills with others, enriching the wider
              community.
            </Text>
          </Box>
          <Box
            spaceY={12}
            className='lg:col-span-2'
          >
            <Text
              as='h2'
              size={40}
              color='pantone'
            >
              About Luuk
            </Text>
            {reasons.map((reason, index) => (
              <Box
                spaceY={4}
                key={index}
              >
                <Text
                  as='h3'
                  size={24}
                >
                  {reason.title}
                </Text>
                <Text as='p'>{reason.tagline}</Text>
              </Box>
            ))}
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
