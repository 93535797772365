import React, { Fragment } from 'react';
import Hero from './Hero';
import List from './List';
import { Helmet } from 'react-helmet-async';

const ServicesPage = () => {
  return (
    <Fragment>
      {/* seo */}
      <Helmet>
        <title>Luuk | Services</title>
        <meta
          name='description'
          content='Are you in search of a skilled and reliable bridal makeup artist and hairstylist in the United States? Luuk provides convenient mobile services for weddings, special occasions, and destination weddings.'
        />
      </Helmet>

      <Hero />
      <List />
    </Fragment>
  );
};

export default ServicesPage;
