import { cva } from 'class-variance-authority';
import layout from '../../theme/layout';
import padding from '../../theme/padding';
import margin from '../../theme/margin';
import position from '../../theme/position';
import space from '../../theme/space';

const boxVariants = cva('', {
  variants: {
    display: layout.display,
    height: layout.height,
    width: layout.width,
    p: padding.normal,
    px: padding.xAxis,
    py: padding.yAxis,
    pb: padding.bottom,
    pt: padding.top,
    ps: padding.start,
    pe: padding.end,
    m: margin.normal,
    mx: margin.xAxis,
    my: margin.yAxis,
    mt: margin.top,
    mb: margin.bottom,
    ms: margin.start,
    me: margin.end,
    position: position.type,
    space: space.default,
    spaceX: space.xAxis,
    spaceY: space.yAxis,
  },
});

export default boxVariants;
